export function checkWXEnv() {
    return new Promise((resolve, reject) => {
        let ua = navigator.userAgent.toLowerCase()
        if ((ua.match(/MicroMessenger/i)) == "micromessenger" && !(/wxwork/i.test(navigator.userAgent))) {
            wx.miniProgram.getEnv((res) => {
                if (res.miniprogram) {
                    resolve('miniprogram')
                } else {
                    resolve('wxh5')
                }
            })
        } else {
            resolve('outwx')
        }
    })
}

export function URLParam(key) {
    // 获取参数
    let url = window.location.href;
    let value = url.split(key + '=')[1];
    if (value && value.indexOf('&')) {
        value = value.split('&')[0]
    }
    if (value && value.indexOf('#/')) {
        value = value.split('#/')[0]
    }
    return decodeURIComponent(value)
}

export function doiagModel(msg, _this) {
    // window.alert('提示', msg, [
    //     { text: '确认', onPress: () =>{
    //         if(msg=='请开启访问动作与方向授权'){
    //             window.DeviceMotionEvent.requestPermission().then(response=>{
    //                 if(response=='granted'){
    //                     // this.navInit()
    //                 }else{
    //                     doiagModel("授权失败,请重新进入页面")
    //                 }
    //         })
    //     }
    //     } },
    // ]);

    _this.$dialog.confirm({
        title: '提示',
        message: msg,
    }).then(() => {
        // on confirm
        if (msg == '请开启访问动作与方向授权') {
            window.DeviceMotionEvent.requestPermission().then(response => {
                if (response == 'granted') {
                    // this.navInit()
                } else {
                    doiagModel("授权失败,请重新进入页面")
                }
            })
        }
    }).catch(() => {
        // on cancel
        if (msg == '请开启访问动作与方向授权') {
            window.DeviceMotionEvent.requestPermission().then(response => {
                if (response == 'granted') {
                    // this.navInit()
                } else {
                    doiagModel("授权失败,请重新进入页面")
                }
            })
        }
    });
}
export function getPermission(_this) {
    if (window.DeviceOrientationEvent) {
        console.log(window.DeviceMotionEvent.requestPermission, 'window.DeviceMotionEvent.requestPermission')
            // if (window.navigator.userAgent.indexOf('MicroMessenger/7.0.12') > -1 &&window.navigator.userAgent.indexOf('iPhone OS 13') > -1){
        if (typeof(window.DeviceMotionEvent) !== "undefined" && typeof(window.DeviceMotionEvent.requestPermission) === "function") {
            window.DeviceMotionEvent.requestPermission().then(response => {
                console.log(response, '罗盘response')
                if (response == 'granted') {
                    // this.navInit()
                    console.log('成功')
                } else {
                    doiagModel("授权失败,请重新进入页面", _this)
                        // Toast({
                        //   message: '授权失败,请重新进入页面',
                        //   position: "bottom"
                        // });
                }

            }).catch(() => {
                doiagModel("请开启访问动作与方向授权", _this)
                    // Toast({
                    //   message: '请开启访问动作与方向授权',
                    //   position: "bottom"
                    // });
                console.log('请开启访问动作与方向授权')
            })
        }
        // }
    }
}

export function wxShare(link, shareData, cb) {
    let { title, desc, icon } = shareData;
    // console.log('link', link);
    console.log('====================================');
    console.log('---------标题----------', title)
    console.log(link, '=====>link')
    wx.ready(function() {
        wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc || '', // 分享描述
            link: link || 'https://img1.baidu.com/it/u=3623690592,694811009&fm=26&fmt=auto&gp=0.jpg', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: icon || localStorage.getItem('shareIcon'), // 分享图标
            success: function() {
                // 设置成功
                console.log('====================================');
                console.log('分享设置成功');
                console.log('====================================');
                cb && cb()
            },
            fail: function(err) {
                console.log('分享失败打印：', err)
            }
        })

    });
    wx.error(function(res) {});



}

export function cdelUrlParams(url, name) {
    //根据#号拆分
    let poundArr = url.split('#')
        //？拆分
    let questionArr = []
    if (poundArr) {
        //把#接上
        poundArr.forEach((element, index) => {
            if (index > 0) {
                element = '#' + element
            }

            let tempArr = element.split('?')
            if (!tempArr) {
                return true
            }
            tempArr.forEach((item, idx) => {
                //保留问号
                if (idx > 0) {
                    item = '?' + item
                }
                questionArr.push(item)
            })
        });
    } else {
        questionArr = url.split('?')
        if (questionArr) {
            questionArr.forEach((item, idx) => {
                if (idx > 0) {
                    item = '?' + item
                }
            })
        }
    }

    if (!questionArr) {
        return url
    }

    //&符号的处理
    let andArr = []
    questionArr.forEach((item, index) => {
        let andIdx = item.indexOf('&')
        if (andIdx <= -1) {
            andArr.push(item)
            return true
        }

        let tempAndArr = item.split('&')
        tempAndArr.forEach((ele, idx) => {
            if (idx > 0) {
                ele = '&' + ele
            }
            andArr.push(ele)
        })
        1
    })


    let newUrl = ''
    andArr.forEach(item => {
        let nameIndex = item.indexOf(name + '=')
            //不拼接要删除的参数
        if (nameIndex > -1) {
            //保留第一个问号
            let questionIdx = item.indexOf('?')
            if (questionIdx == 0) {
                newUrl += '?'
            }
            return true
        }
        newUrl += item
    })

    return newUrl.replace(/\?\&/g, "?")
}