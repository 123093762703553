import * as egps from "@/assets/mapjs/egps.min.js";
class Gps {
    constructor() {
        this.success=null;
        this.fail = null;
        this.gps = new egps.position.GPS();
        this.stepper = null;
        // this.blueTooth = new egps.position.BlueTooth();
        this.fusion = new egps.position.Fusion();
        this.position=egps.position;
    }

    start(success, fail) {
        this.success = (loca)=>{
            this.stepper.changeStepLnglat(loca);
            success(loca)
        };
        this.gps.openWatcher() //（gps定位）
            //获取当前定位点
        this.gps.getCurrentPosition().then(res => {
            console.log('openStepper====')
            this.stepper = new egps.position.Stepper(res);
            this.stepper.openStepper(); //（Stepper定位）
            // this.blueTooth.on(); //（BlueTooth定位）
            this.fusion.openFusion() //（融合定位）
            egps.position.on('fusion', this.success)//融合定位
            // egps.position.on('alpha', (loca) => { // 罗盘更新
            //     console.log('alpha罗盘', loca)
            // })
        })
        this.fail = (err) => {
            fail(err)
        }
    }

    stop() {
        this.success&&egps.position.off('fusion',this.success);
        this.success=null;
        this.gps.closeWatcher() //（gps定位）
        this.stepper&&this.stepper.destoryStepper(); //（Stepper定位）
        // this.blueTooth.off();//（BlueTooth定位）
        this.fusion.closeFusion() //（融合定位）
    }
}
export default Gps
//
//
//
//
// let _watcher = null;
// const start = (success, fail) => {
//     const options = {enableHighAccuracy: true, timeout: 30000, maximumAge: 1500};
//     _watcher && navigator.geolocation.clearWatch(_watcher);
//     _watcher = navigator.geolocation.watchPosition(success, fail, options);
//     return _watcher
// };
// const stop = watcher => {
//     if (watcher || _watcher) {
//         navigator.geolocation.clearWatch(watcher || _watcher);
//         _watcher = null;
//     }
// };
//
// export default {
//     start,
//     stop
// }
