<template>
  <div class="home">
    <div id="map" ref="mapContainer"></div>
    <div class="topaddress">
      <div class="assrebox">
        <div class="address">
          <img src="../assets/img/qi.png" />
          <div>我的位置</div>
        </div>
        <div class="ge"></div>
        <div class="address">
          <img src="../assets/img/zhong.png" />
          <div>{{destination}}</div>
        </div>
      </div>
    </div>
    <div class="showju">
      <div class="jubox">
        <div class="dangqian">
          <div>当前</div>
          <div class="outlog">
            <img src="../assets/img/Volume-notice.png" />
            <div>退出</div>
          </div>
        </div>
        <div class="addressname">
          距离目的地218米 耗时约 7分钟
        </div>
        <div class="mudidi">
          <img src="../assets//img/Local.png" />
          <div>目的地：<span>{{destination}}</span></div>
        </div> 
      </div>
    </div>
    <div class="loading" v-if="showloading">
      <div class="loadingbox">
        <img src="../assets/img/loading.png" />
        <div>正在重新规划路线</div>
      </div>
    </div>

    <!-- <WarnIndex ref="WarnIndex" v-if="isHomePagePath" :planMsg="pathActive" :findfootauto="findfootauto" /> -->
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import { maproutev3findfootauto } from "@/api/warnApi";
import { URLParam } from "@/utils";
import freeIMG from "@/assets/img/naving.png";
import * as creeper from "@/assets/mapjs/bundle.min.js";
import Gps from "@/services/location/gps";
import { lineString, length, point, polygon, bbox, booleanPointInPolygon, bboxPolygon } from "@turf/turf";
const gps = new Gps();
// import WarnIndex from "@/components/Navigations.vue";
export default {
  name: "HomeView",
  components: {
    // WarnIndex,
  },
  data() {
    return {
      showloading:false,
      freeMarker: null,
      destination:'', //目的地
      carNumber:'',//车牌号
      isHomePagePath: false, //导航页面
      findfootauto:[], //规划路线
      pathActive: {
        storageName: "",
      },
      recommendActive: {
        storageName: "",
      },
      numberplate: "",
      isMapTrue: false, //地图加载时间
    };
  },
  computed: {
    ...mapGetters(["map", "location", "isHomePage", "isLoading"]),
  },
  watch: {
    location(e) {
      console.log("坐标变化？？", e);
      if (e.lon && e.lat && this.freeMarker) {
        !this.freeMarker.show && this.freeMarker._add();
        this.freeMarker.setLngLat([e.lon, e.lat]);
      }
      if (!(this.location && this.location.lon)) {
        console.log("无法获取当前定位");
      } else {
      }
    },
  },
  created() {
    console.log("链接携带的==-：", URLParam("lng")+','+URLParam("lat"));

    if (URLParam("lng")) {
      console.log("链接携带的==-998：", decodeURI(URLParam("lng")));
      this.destination = URLParam("location")
      this.carNumber = URLParam("carNumber")
      this.recommendList = [
        {storageName: "起点",location:'85.86008852076134,44.26916212179887'},
        {storageName: "终点",location: URLParam("lng")+','+URLParam("lat")}
      ];
      maproutev3findfootauto({start:'85.86008852076134,44.26916212179887',end:URLParam("lng")+','+URLParam("lat"),mapId:1}).then(res => {
        console.log('33333',res)
        this.findfootauto = res.data;
      })
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    ...mapActions(["SET_MAP", "UPDATE_LOCATION", "SET_ISHOMEPAGE", "SET_LOADING"]),

    initMap() {
      let map = null;
      map = new creeper.VectorMap("map", window.g.MAP_ID, window.g.CONFIG_CMGIS);
      this.SET_MAP(map);
      map.on("load", () => {
        this.openGPS();
        if (this.freeMarker) {
          this.freeMarker._remove();
        }

        this.freeMarker = this.createMarker({
          type: "free",
          class: "free-marker",
          lon: this.location.lon || 85.86008852076134,
          lat: this.location.lat || 44.26916212179887,
          show: true,
          imgSrc: freeIMG,
        });
        var newCenter = [this.location.lon || 85.86008852076134,this.location.lat || 44.26916212179887]
        map.setCenter(newCenter);
        // this.map.on("click", (e) => {
        //   console.log("点击地图", e);
        // });
        setTimeout(() => {
          this.isHomePagePath = true;
          this.isMapTrue = true;
        }, 5000);
      });
    },
    createMarker(msg) {
      // console.log("细化怒数据", msg);
      if (!msg.lon || !msg.lat) {
        // console.log("传入数据：", msg);
        throw new Error("传入坐标有误");
      }
      let div, img;
      if (msg.type) {
        div = document.createElement("div");
        div.classList.add("created-marker");
        msg.class && div.classList.add(msg.class);
        msg.id && (div.id = msg.id);

        img = document.createElement("img");
        img.alt = "";
        img.src = msg.imgSrc;

        div.appendChild(img);
      }
      const marker = new creeper.Marker(div).setLngLat([msg.lon, msg.lat]);
      marker.msg = msg;

      marker._remove = () => {
        marker.remove();
        marker.show = false;
      };
      marker._add = () => {
        marker.show = true;
        marker.addTo(this.map);
      };

      msg.show && marker._add();

      return marker;
    },
    // 选中预约点
    recommendClick(item, index) {
      if (this.isMapTrue) {
        if (this.location && this.checkInBounds(this.location, this.map)) {
          console.log("选中预约", item, index);
          if (this.recommendActive.storageName == item.storageName) return;
          this.recommendActive = item;
          this.pathActive = item;
          this.indexActive = index;
          this.SET_LOADING(true);
        } else {
          this.$message({
            showClose: true,
            message: "未获取到定位或定位不在园区内，请稍后再试",
            type: "warning",
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "正在获取数据中心，请等待...",
          type: "warning",
        });
      }
    },
    checkInBounds({ lon, lat } = {}, map = {}) {
      if (!map || !map.configComponent.mapZone.polygonBBox || !lon || !lat) {
        return false;
      }
      const curr = point([lon, lat]);
      // const _bbox = bboxPolygon([
      //   bounds._sw.lng,
      //   bounds._sw.lat,
      //   bounds._ne.lng,
      //   bounds._ne.lat,
      // ]);
      // const _polygonBbox = polygon(_bbox.geometry.coordinates);

      return booleanPointInPolygon(curr, map.configComponent.mapZone.polygonBBox);
    },
    //导航页面
    butLoction() {
      // this.recommendActive;
      console.log("选中预约==--11", this.recommendActive);
      if (!this.recommendActive.storageName) {
        this.$message({
          showClose: true,
          message: "请选择预约终点",
          type: "warning",
        });
        return;
      }
      console.log("是否有定位", this.location);
      if (this.location && this.location.lon && this.location.lat) {
        this.$refs.WarnIndex.realNav();
        this.SET_ISHOMEPAGE(false);
        this.recommendActive = {};
        // this.recommendList.splice(this.indexActive, 1);
        const item = this.recommendList[this.indexActive];
        this.moveItemToEnd(this.recommendList, item);
        console.log("是否有定位", this.recommendList);

        this.indexActive == -1;
      } else {
        gps.stop(GLOBAL_GEO_WATCHER_ID);
        const gpsSuccessCallback = (res) => {
          console.info("定位成功", JSON.stringify(res));
          res.type &&
            this.UPDATE_LOCATION({
              accuracy: 0,
              ...res,
              lon: res.longitude,
              lat: res.latitude,
              level: res.floor || null,
              scene: res.source,
              free: true,
              show: true,
            });
        };
        const gpsFailCallback = (err) => {
          console.error("gps定位失败:", err);
        };
        GLOBAL_GEO_WATCHER_ID = gps.start(gpsSuccessCallback, gpsFailCallback);
      }
      //
    },
    moveItemToEnd(arr, item) {
      const index = arr.findIndex((element) => element === item);
      console.log("arritem", arr, item, index);
      if (index !== -1) {
        arr.splice(index, 1); // 从数组中移除选中的数据
        arr.push(item); // 将选中的数据放在数组的最后一位
      }
    },
    // 定位和导航
    /**GPS定位启动*/
    openGPS() {
      const gpsSuccessCallback = (res) => {
        alert("定位成功".JSON.stringify(res));
        res.type &&
          this.UPDATE_LOCATION({
            accuracy: 0,
            ...res,
            lon: res.longitude,
            lat: res.latitude,
            level: res.floor || "0",
            scene: res.source,
            free: true,
            show: true,
          });
      };
      const gpsFailCallback = (err) => {
        console.error("gps定位失败:", err);
      };
      gps.start(gpsSuccessCallback, gpsFailCallback);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  #map {
    width: 100%;
    height: 100%;
  }
  .topaddress{
    position: absolute;
    top: 10px;
    width: 95%;
    left: 2.5%;
    background-color: #fff;
    border-radius: 5px;
    padding: 2.5%;
    .assrebox{
      width: 100%;
      background-color: rgba(244, 244, 244, 0.70);
      border-radius: 4px;
      padding: 5px 0;
      .address{
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #333;
        img{
          width: 8px;
          height: 8px;
          margin-left: 10px;
          margin-right: 5px;
        }
      }
      .ge{
        width: 1px;
        height: 15px;
        background-color: #E2E2E2;
        margin-left: 14px;
      }
    }
   
  }
  .showju{
    position: absolute;
    bottom: 10px;
    width: 95%;
    left: 2.5%;
    background-color: #fff;
    border-radius: 5px;
    padding: 2.5%;
    .jubox{
      width: 100%;
      padding: 5px 0;
      .dangqian{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #333;
        .outlog{
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #999999;
          img{
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
      .addressname{
        font-size: 13px;
        color: #999999;
        margin-top: 10px;
        text-align: left;
      }
      .mudidi{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        font-size: 13px;
        color: #666;
        img{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        span{
          color: #1DA0FF;;
        }
      }
    }
    
  }
  .loading{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    z-index: 99;
    .loadingbox{
      margin-top: 45%;
      font-size: 14px;
      color: #FFFFFF;
      img{
        width: 20px;
        height: 40px;
        animation: rotate 1s linear infinite;
      }
      @keyframes rotate {
        from {
          transform: rotate(0deg); /* 动画开始时图片的角度 */
        }
        to {
          transform: rotate(360deg); /* 动画结束时图片旋转360度 */
        }
      }
      div{
        margin-top: 16px;
      }
    }
  }

  // <div class="loading" v-if="showloading">
  //     <div class="loadingbox">
  //       <img src="../assets/img/loading.png" />
  //       <div>正在重新规划路线</div>
  //     </div>
  //   </div>
}

.one-home {
  position: absolute;
  top: 0;
  right: 0;
  color: #cfcfcf;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background: #eceef0;
  // background: #00000030;
  // box-shadow: -3px 0px 4px 0px rgba(0, 0, 0, 0.08);
}
.home-bg {
  width: 100vw;
  height: 51vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.home-main {
  width: calc(100% - 30px);
  max-height: 70%;
  // padding: 0 16px;
  // background: #eab6b6;
  border-radius: 10px;
  position: absolute;
  top: 40vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  .ve-query {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 14px;
    background: #fff;
    border-radius: 10px;
    .numberplate {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: #666;
      align-items: center;
      margin: 8px 0;
      .numberplate-left {
        img {
          width: 22px;
        }
      }
      .numberplate-right {
        color: #0085ff;
      }
    }
    .reservation {
      width: 100%;
      max-height: 50vh;
      overflow: hidden;
      overflow-y: scroll;
      .reservation-main {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 8px 12px 10px;
        margin: 14px 0;
        background: rgba(216, 216, 216, 0.34);
        position: relative;
        border: rgba(216, 216, 216, 0.34) 1px solid;
        img {
          width: 16px;
          height: 22px;
        }
        .reservation-title {
          font-size: 16px;
          color: #666;
          font-weight: 700;
          margin: 0 10px;
        }
        .reservation-where {
          flex: 1;
          text-align: start;
          font-size: 18px;
          font-weight: 500;
          color: #999;
        }
        .recommend {
          font-size: 12px;
          color: #f97b07;
          border-radius: 0px 4px 0px 10px;
          padding: 2px 8px 2px 10px;
          background: #faeddf;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .reservation-act {
        background: rgba(0, 133, 255, 0.34);
        border: rgba(0, 133, 255, 0.34) 1px solid;
        .reservation-where {
          color: #0085ff;
        }
      }
    }
    .bbut {
      display: flex;
      justify-content: space-evenly;
      margin: 40px 0 10px;
      .bbut-close {
        font-size: 18px;
        padding: 8px 38px;
        color: #9c9c9c;
        border-radius: 20px;
        border: 1px solid #dcdcdc;
        background: #fff;
      }
      .but-loction {
        font-size: 18px;
        border-radius: 20px;
        background: #0085ff;
        color: #fff;
        width: 110px;
        text-align: center;
        .el-button {
          font-size: 18px;
          background-color: #007ef2;
          border-color: #007ef2;
          border-radius: 20px;
          width: 100%;
        }
      }
    }
  }
}

.containers {
  box-sizing: border-box;
  width: 100%;
}

.car_input_box {
  width: 100%;
  height: 38vh;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding: 6px;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
  align-items: center;
}

.font-30 {
  font-size: 30px;
}

.color-333 {
  color: #333333;
}

.car_type {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  align-items: center;
}

.default_car {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.line {
  color: #ea4070;
  border-radius: 2px;
  animation: fade 1500ms infinite;
  -webkit-animation: fade 1500ms infinite;
}

.car_type_box {
  display: flex;
  justify-content: start;
  width: 390px;
  margin: 0 auto;
}

.car_type_item {
  width: 130px;
  height: 69px;
  border: 1px solid #999999;
  text-align: center;
  line-height: 69px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.check_energy {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6d7278;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.check_energy img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.car_type_item:nth-child(1) {
  border-radius: 6px 0px 0px 6px;
  border-right: 0.5px solid #999999;
}

.car_type_item:nth-child(2) {
  border-right: none;
  border-left: none;
}

.car_type_item:nth-child(3) {
  border-radius: 0px 6px 6px 0px;
}

.active {
  width: 130px;
  height: 69px;
  border: 1px solid #999999;
  text-align: center;
  line-height: 69px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  font-weight: bold;
  color: #ea4070;
  background: rgba(#ea4070, 0.1);
}

.active:nth-child(1) {
  border-radius: 6px 0px 0px 6px;
  border-right: 0.5px solid #999999;
}

.active:nth-child(2) {
  border-right: none;
  border-left: none;
}

.active:nth-child(3) {
  border-radius: 0px 6px 6px 0px;
}

.car_number_input {
  margin-top: 40px;
}

.tips {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6d7278;
  margin: 16px 10px;
}

.add_car_box {
  width: calc(100% - 64px);
  height: 80px;
  background: linear-gradient(180deg, #df4270, #f299ab);
  border-radius: 40px;
  margin: 0 auto;
}

.add_car {
  height: 100%;
  line-height: 80px;
  font-size: 34px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.dis_car {
  height: 100%;
  line-height: 80px;
  font-size: 34px;
  font-weight: 500;
  color: #ffffff;
  background: #999999;
  text-align: center;
  border-radius: 40px;
}

.keyboard-content {
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(205, 208, 212, 0.92);
  padding-top: 13px;
  z-index: 5;
}

.td {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin: 4px 1px;
  background: #fcfcfe;
  box-shadow: 0px 2px 0px 0px #898a8d;
  border-radius: 9px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #fff;
}

.province-keyboard {
  // margin: 0 5px;
  padding: 0 4px;
}

.td-nor {
  // flex: 0 1 9%;
  // margin-right: 3px;
}

.number-keyboard {
  margin: 0 5px;
}

.board-active {
  box-shadow: 0 0 0 #e5e5e5;
  background: #e5e5e5;
}

.english-keyboard {
  margin: 0 5px;
  flex-wrap: wrap;
}

.td-num {
  flex: 0 1 9%;
}

.board-active {
  box-shadow: 0 0 0 #e5e5e5;
  background: #e5e5e5;
}

.delete {
  width: 72px;
  height: 36px;
  text-align: center;
  background-color: #aaadb7;
  border-radius: 8px;
  // position: absolute;
  // right: 8px;
  // bottom: 30px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 0px 0px #898a8d;
}

.del-province {
  position: absolute;
  right: 8px;
  bottom: 5px;
  width: 36px;
  font-family: "PingFangSC";
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  background: #fcfcfe;
  box-shadow: 0px 2px 0px 0px #898a8d;
  border-radius: 9px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #aaadb7;
}

.sure {
  width: 100px;
  height: 84px;
  text-align: center;
  background-color: #afb2bc;
  border-radius: 8px;
  position: absolute;
  right: 10px;
  bottom: 30px;
  justify-content: center;
  align-items: center;
}

.default_dot {
  width: 4px;
  height: 4px;
  background: #333333;
  margin: 0 2px;
}

.del-con {
  width: 32px;
  height: 30px;
}

.jus-center {
  justify-content: center;
}

.diabled_car {
  background: #f5f5f5;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.active_car {
  border-radius: 4px;
  border: 2px solid #375de9;
}

.province-mr {
  margin-right: 10px;
}

.confirm_btn {
  width: 100%;
  height: 92px;
  line-height: 92px;
  background: #11b690;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border-radius: 46px;
  margin-top: 40px;
}
.seachBut {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  .el-button {
    font-size: 18px;
    background-color: #007ef2;
    border-color: #007ef2;
    border-radius: 20px;
    width: 50%;
  }
}

.province-td {
  width: 12.5%;
}

.province-font {
  width: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin: 4px auto;
  box-shadow: 0px 2px 0px 0px #898a8d;
  border-radius: 9px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #fff;
}

.confirm-cash-box {
  width: 100vh;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.cash-box {
  width: 570px;
  //height: 300px;
  background: rgb(223, 223, 223);
  border-radius: 28px;
  margin: 30vh auto 0;
  text-align: center;
  min-height: 200px;
}

.cash-title {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-top: 38px;
  margin: 0 0 24px;
}

.content-cash {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 54px;
  padding: 0 32px;
}

.line-cash {
  width: 100%;
  height: 1px;
  background: rgba(60, 60, 67, 0.29);
}

.cancel-box {
  width: 100%;
  height: 88px;
  line-height: 88px;
  color: #11b690;
  font-size: 32px;
  text-align: center;
}

.flex-btwn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
