import Request from "./request";

// 轨迹查询
export function getVehicleTrajectoryQiguo(data) {
  return Request.get("/business/trajectoryInfo/location/list", data);
}
// 根据车牌查询预约点
export function getFnumberplateList(plate) {
  return Request.get(`/business/trajectoryInfo/storage/list?plate=${plate}`);
}
//定位上传 business/trajectoryInfo/add
export function trajectoryInfoAdd(data) {
  return Request.service({
    url: "/business/trajectoryInfo/add",
    method: "post",
    data,
  });
}
// 根据
export function getFnumberpla356545teList(data) {
  return Request.service({
    url: "/business/trajectoryInfo/storage/list",
    method: "post",
    data,
  });
}


export function maproutev3findfootauto(data){
  return Request.get("map/route/v3/find/foot/auto", data);
}