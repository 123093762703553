<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Gps from "@/services/location/gps";
import { lineString, length, point, polygon, bbox, booleanPointInPolygon, bboxPolygon } from "@turf/turf";
const gps = new Gps();
export default {
  data() {
    return {};
  },
  mounted() {
    this.openGPS();
  },
  beforeDestroy() {
    gps.stop(GLOBAL_GEO_WATCHER_ID);
  },
  methods: {
    ...mapActions(["SET_MAP", "UPDATE_LOCATION"]),
    openGPS() {
      const gpsSuccessCallback = (res) => {
        console.info("定位成功", JSON.stringify(res));
        res.type &&
          this.UPDATE_LOCATION({
            accuracy: 0,
            ...res,
            lon: res.longitude,
            lat: res.latitude,
            level: res.floor || "0",
            scene: res.source,
            free: true,
            show: true,
          });
      };
      const gpsFailCallback = (err) => {
        console.error("gps定位失败:", err);
      };
      GLOBAL_GEO_WATCHER_ID = gps.start(gpsSuccessCallback, gpsFailCallback);
    },
  },
};
</script>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  color: #2c3e50;
  background: #f6f6f6;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //   设置字体禁止缩放
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  -webkit-overflow-scrolling: touch;
  .mapClickStartMarker {
    width: 30px;
    height: 30px;
    background: url(~@/assets/img/mapClickStartMarker.png) no-repeat center;
    background-size: cover;
    z-index: 0;
  }
  .mapClickEndMarker {
    width: 30px;
    height: 30px;
    background: url(~@/assets/img/mapClickEndMarker.png) no-repeat center;
    background-size: cover;
    z-index: 0;
  }
  .click-marker,
  .start-marker,
  .end-marker,
  .nav-marker {
    width: 6vw;
    height: 6vw;

    img {
      transform: translateY(-10px);
    }
  }
  .click-marker {
    width: 32px;
    height: 64px;

    img {
      transform: translateY(0%) translateX(0%);
    }
  }

  .free-marker {
    img {
      width: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -12px 0 0 -12px;
    }
  }

  .nav-marker {
    img {
      width: 42px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -21px 0 0 -21px;
    }
  }
  input {
    border: none;
    background: none;
    outline: none;
    height: 30px;
    width: 100%;
    background-color: #F5F7FA;
    border-radius: 6px;
    padding-left: 24px;
  }

  input::placeholder {
    color: #a7a7a8;
  }
}
</style>
