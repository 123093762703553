import axios from "axios";
import store from "@/store";
const CancelToken = axios.CancelToken;
// const Host = "https://gis.uestc.edu.cn/"
const service = axios.create({
  baseURL: window.g.HOST_URL, // api的base_url
  timeout: 500000, // request timeout
});

service.interceptors.request.use(
  async (config) => {
    config.cancelToken = new CancelToken((c) => {
      // console.log('b保存请求999',store.state,);

      store.dispatch("SET_CANCELTOKENS", c);
      // console.log('b保存请求99900000',store.state,);
      // myRequestStore.pushToken(c)// 这里的c就是取消请求的方法
    });
    config.headers = {
      Authorization:'Basic ' + 'b3NTZ2RualJBeVFxd0JveXRFS1ErRkFiSDlGeFI4Nkg4b0MxYVBjYnZIS2VITSt1cjY5bVkvaklVazdYWFArL2NVdGwxc2F1ellWOVpGVmVFelNSeW5XVlRMUmFWWi96S1JHTUVPTEJuVDVHSk9UbzczMmpTWGlLbzBjY2ZMUkozMzhRcmdsSVZVUlJDcGRlQXlzM3ZuMzh4RzlWeitBM0NFQzZCNHlWT2drPTpkUVVPNi9VM2VFYjdKdDBycWVlN0o3OHBvUCtmQVhxUk1IVThNaVN1ZlVibGZRS1lEV0lON1IvN05BRUk2RjhCWmgxTlIrcHhVOG9iQVpSSTF0dEpnRlR5QWZnRW9FbUx0VUV0NHN3Qk1relk3UTNDTDFKR0ZhR0lZM2FzMnJseGVPQ0xmaW1jRnl6Vnl5RkFBSXlYSzZDdWxlTTkzTkJndTBMeCtheUxKM2M9'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    //   return Promise.resolve(response.data);
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response.data);
    }
  },
  async (error) => {
    return Promise.reject(error);
  }
);

function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "get",
      params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function post(url, { params = {}, data = {} }) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "post",
      data,
      params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postUrl(url, data) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "post",
      params: data,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  get,
  post,
  postUrl,
  service
};
