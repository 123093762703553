import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  cancelTokens: [], //接口请求数组-用作态势切换拦截遗留请求
  mapClickData: "", //地图点击
  map: null,
  // location: {lon: 104.08314242942419, lat: 30.636436679482998},
  location: {},
  isHomePage: true,
  isLoading: false,
};

const mutations = {
  // 添加所有请求数据
  SET_CANCELTOKENS(state, cancelToken) {
    state.cancelTokens.push(cancelToken);
  },
  SET_MAP(state, map) {
    state.map = map;
  },
  SET_MAPCLICK(state, data) {
    state.mapClickData = data;
  },
  UPDATE_LOCATION(state, e) {
    state.location = { ...e };
  },
  SET_ISHOMEPAGE(state, data) {
    state.isHomePage = data;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
};
const actions = {
  // 设置添加所有请求接口
  SET_CANCELTOKENS(Store, cancelToken) {
    Store.commit("SET_CANCELTOKENS", cancelToken);
  },
  //  清除前期所有请求
  SET_CANCELREQUEST() {
    console.log("b保存请求999qingcjhu", state.cancelTokens);
    if (state.cancelTokens.length === 0) return;
    state.cancelTokens.forEach((item) => {
      item();
    });
    state.cancelTokens = [];
  },
  SET_MAP(Store, map) {
    Store.commit("SET_MAP", map);
  },
  SET_MAPCLICK(Store, mapclick) {
    Store.commit("SET_MAPCLICK", mapclick);
  },
  UPDATE_LOCATION(store, e) {
    store.commit("UPDATE_LOCATION", e);
  },
  SET_ISHOMEPAGE(Store, isHomePage) {
    Store.commit("SET_ISHOMEPAGE", isHomePage);
  },
  SET_LOADING(Store, loading) {
    Store.commit("SET_LOADING", loading);
  },
};
const getters = {
  cancelTokens: (state) => state.cancelTokens,
  mapClickData: (state) => state.mapClickData,
  map: (state) => state.map,
  location: (state) => state.location,
  isHomePage: (state) => state.isHomePage,
  isLoading: (state) => state.isLoading,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {},
  getters,
});
